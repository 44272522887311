.heading h1{
    color:#015243;
    text-align: center;
    margin-top: 3rem;
    font-size: 70px;
    font-weight: 500;
  }
  .about_background{
    display: flex;
    width: 100%;
    min-height: 80vh;
  }
  .about_container{
    margin: auto;
  }
  .float_container {
    display: flex;
    gap: 50px;
  
  }
  .about_img {
    width: 60%;
    margin-top: 1rem;
    border-radius: 5px;
    
    box-shadow: 50px 50px 1px #015243;
    /* border-bottom:2rem solid #015243;
    border-right: 2rem solid #015243;
    border-top: 2rem solid #015243; */
  }
  
  .float_child { 
    width: 50%;
    padding: 10px 15px;
  } 
  
  .left{
    padding-top: 4rem;
    padding-left: 10rem;
    color: white;
  
   
  }
  .left h1{
    font-size: 2.5rem;
    font-weight: 400;
    color: black;
  }
  .left p{
    color: black;
    font-size: 20px;
    font-weight: 400;
  }
  .about_form_group{
    display: flex;
  }

  
  @media screen and (max-width: 900px) {
    .heading h1{
      margin:2rem 0 0.5rem 0;
      font-size: 32px;
      font-weight: 600;
    }
    .about_background{
        min-height: 40vh;
    }
    .about_container{
        margin: auto;
        padding: 10px;
      }
    .float_container{
        display: flex;
        flex-direction: column-reverse;
    }
    .float_child{
    width: 100%;
  }
    .about_img {
      width: 100%;
      margin-top: 0.1rem;
      box-shadow: none;
    }
  
  .left{
    padding: 0.1rem 1rem;
  }
  .left h1{
    font-size: 1.8rem;
  }
  .left p{
    font-size: 16px;
    margin-bottom: 3rem;
  }
  
  .about_form_group{
    display: flex;
    flex-direction: column;
  }

  
  }