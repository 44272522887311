/* body{
  background-color: #FAECE1;
} */


.heading h1{
  font-family: 'Darker Grotesque', sans-serif;
  color:#015243;
  text-align: center;
  margin-top: 2rem;
  font-size: 80px;
  font-weight: 400;
}
.home_background{
  display: flex;
  width: 100%;
  min-height: 80vh; 
}
.home_container{
  margin: auto;
}
.float_container {
  display: flex;
  gap: 15rem;
}
.home_img {
  width: 100%;
  /* margin-top: 1rem; */
}
/* 
.float_child { 
  padding: 10px 15px;
}  */

.left{
  padding-top: 2rem;
  padding-left: 2rem;
  color: white;
  width: 40%;
}
.right {
  width: 100%;
}

.left h1{
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
  color: #015243;
}
ul li{
  font-family: 'Darker Grotesque', sans-serif;
  margin-left: 3.5rem;
  font-weight:500;
  font-size: 2.5rem;
  color: #015243;
  /* line-height:55px; */
}
.left p{
  font-family: 'Darker Grotesque', sans-serif;
  color: #015243;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height:25px;
  margin-bottom: 3rem;
}
.home_form_group{
  display: flex;
  justify-content: flex-end;
}
.home_form_button {
  background: #015243;
  border: none;
  border-radius: 50px;
  padding: 15px 10px;
  width: 80%;
  color: rgb(207, 199, 199);
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  margin-right: 3.5rem;
}

.home_form_button:hover {
  color: white;
}

.footer{
  display: flex;
  justify-content: space-evenly;
  margin: 8rem 0.5rem 4rem 0.5rem;
}
.footer a {
  text-decoration: none;
  color:#015243;
}
@media screen and (max-width: 900px) {
  .heading h1{
    margin: 2rem 0 0.5rem 0;
    font-size: 25px;
    font-weight: 600;
  }
  .home_background{
    min-height: 40vh;
  }
  .home_container{
      margin: auto;
      padding: 10px;     
    }
  .float_container{
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
  }
  .float_child{
    width: 100%;
}

  .home_img {
    width: 100%;
    margin-top: 0.1rem;
  }

.left{
  padding: 0.1rem 1rem;
  width: 100%;
}
.left h1{
  font-size: 1.4rem;
}

.left p{
  font-size: 16px;
  padding: 0 7px;
  margin-bottom: 1rem;
}

ul li {
  font-size: 1.2rem;
  margin-left: 2rem;
}

.home_form_group{
  display: flex;
  flex-direction: column;
}

.home_form_button{
  width: 100%;
  margin-top: 10px;
  padding:10px;
}
.footer a {
 font-size: 10px;
}

}