.Navbar {
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #015243;
	/* padding: 2rem; */
  }

  .nav-logo {
	margin-left: 3rem;
	width: 70%;
	height: 35%;
  }
  
  .Navbar > .nav-items > a {
	color: #87a55b;
	font-size: 16px;
	text-decoration: none;
	margin: 15px;
	margin-right: 3rem;
	position: relative;
	opacity: 0.9;
  }
  
  .Navbar > .nav-items > a:hover {
	opacity: 1;
	color:#64CACF
  }
  
  .Navbar > .nav-items > a::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 2px;
	background: #ffffff;
	transition: all 0.45s;
  }
  
  .Navbar > .nav-items > a:hover::before {
	width: 0%;
  }
  
  .Navbar > .nav-toggle {
	display: none;
  }

  .nav-items button{
	margin-right: 2rem;
	color:#015243;
	border: none;
	font-size: 15px;
	font-weight: 500;
	padding: 5px 15px;
	border-radius: 20px;
  }
	.nav-items button:hover,
	button:active {
  	/* background: #055244; */
  	color: #64CACF;
}

.dropdown button{
	background-color:inherit;
	color: #87a55b;

	/* margin-bottom: 2rem; */
	/* padding: 0; */
	/* padding: 16px; */
	/* margin-bottom: 1rem; */
	font-size: 15px;
	border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
	position: relative;
	display: inline-block;
	
  }
  
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #015243;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	border-radius: 5px;
  }

  .dropdown-content a {
	color: #87a55b;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {color: #64CACF;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  /* .dropdown:hover .dropbtn {background-color: #3e8e41;} */
  
  
  @media (max-width: 900px) {
	
	.Navbar > .nav-items {
	  position: absolute;
	  top: 60px;
	  display: flex;
	  flex-direction: column;
	  background: #0d6d5a;
	  left: 0;
	  width: 60%;
	  height: 100%;
	  transform: translateX(-100%);
	  transition: all .45s;
	  z-index: 10;
	  padding: 1rem;
	 
	}
	.nav-logo {
		margin-left: 0.1rem;
		width: 50%;
		height: 70%;
	  }
	.Navbar > .nav-items > a::before {
		background: transparent;
	}
  
	.Navbar > .nav-items.open {
		transform: translateX(0);
	}
  
	.Navbar > .nav-toggle {
	  display: flex;
	  width: 50px;
	  height: 50px;
	  align-items: center;
	  justify-content: center;
	  cursor: pointer;
	  margin-right: 10px;
	}
  
	.nav-toggle > .bar {
	  position: relative;
	  width: 32px;
	  height: 2px;
	  background: #ffffff;
	  transition: all 0.45s ease-in-out;
	}
  
	.nav-toggle > .bar::before,
	.nav-toggle > .bar::after {
	  content: "";
	  position: absolute;
	  height: 2px;
	  background: #ffffff;
	  border-radius: 2px;
	  transition: all 0.45s ease-in-out;
	}
  
	.nav-toggle > .bar::before {
	  width: 25px;
	  transform: translateY(-8px);
	  right: 0;
	}
  
	.nav-toggle > .bar::after {
	  width: 32px;
	  transform: translateY(8px);
	}
  
	.nav-toggle.open > .bar {
		transform: translateX(-40px);
		background: transparent;
	}
  
	.nav-toggle.open > .bar::before {
		width: 32px;
		transform: rotate(45deg) translate(26px, -26px);
	}
  
	.nav-toggle.open > .bar::after {
	  transform: rotate(-45deg) translate(26px, 26px);
	}
	.nav-items button{
		font-size: 15px;
		width: 60%;
		border-radius: 20px;
	  }

	  .dropdown button{
		margin: 0.5rem 0;
		text-align: left;
	  }
  }