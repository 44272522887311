.privacy{
    margin: 5rem;
}

.heading{
    font-size: 3rem;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .heading{
        font-size: 2.2rem;
        text-align: center;
    }
    .privacy{
        margin: 2rem;
    }

}
