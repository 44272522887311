

.checkbox1 input {
    margin: 20px 15px;
}


.checkbox1{
    position: relative;
  }

  .text {
    color: #106e5b;
  }
  .checkbox1 label {
    background-color: rgb(225, 219, 219);
    border: 1px solid rgb(198, 193, 193);
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    margin: 13px 5px;
    width: 24px;
  }
  
  .checkbox1 label:after {
    border: 2px solid rgb(255, 253, 253);
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 11px;
  }
  
  .checkbox1 input[type="checkbox"] {
    visibility: hidden;
  }
  
  .checkbox1 input[type="checkbox"]:checked + label {
    background-color: #106e5b;
    border-color: #106e5b;
  }
  
  .checkbox1 input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }



  /* .checkbox2 label:after {
    border: 2px solid rgb(255, 253, 253);
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 11px;
  } */
  
