/* body{

    background-color:rgb(253, 251, 247);
} */
.polygon {
    height: 20px;
    width: 20px;
    background-color: #106e5b;
    rotate: 45deg;
    margin-left: 12rem;
    /* margin-top: -1rem; */
}
.heading h2{
    font-size: 2rem;
    color: #106e5b;
    /* margin: 50px 0; */
}
.heading p {
    font-size: 25px;
    color: #4b9385;
}

.line{
    border: 2px solid #106e5b;
    margin-top: -11px;
    margin-bottom: 2.5rem;
}

.button button {
    width: 50%;
    padding: 0.6rem;
    background-color: #ebf7f5;
    border: rgb(16, 11, 2);
    box-shadow: 10px rgb(238, 244, 242);
    border-radius: 10px;
    font-size: 30px;
    color: #106e5b;
}

.instruction {
    margin: 0 0 2rem 0;
}
.instruction p{
    color:#29a189;
    font-size: 25px;
}

.instruction_details {
    margin-left: 25px;
}
input[type=password]{
    color:#106e5b;
    font-weight: 500;
    font-size: 20px;
}

@media (max-width: 700px) {
    .heading h2{
        font-size: 1.6rem;
        margin: 10px 0;
    }
    .instruction p{
        font-size: 18px;
    }
   .button button {
    width: 100%;
   }
}