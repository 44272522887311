.control {
    margin: 1rem 0;
    display: flex;
    align-items:stretch;
    flex-direction: column;
    
  }
  
  .control label,
  .control input {
    display: block;
  }

  .control ::placeholder{
    font-size: 20px;
    color: #4b9385;
    font-weight: 500;
  }
  
  .control label {
    flex: 1;
    font-size: 32px;
    font-weight: 500;
    color: #106e5b;
    margin-bottom: 1rem;
  }
  
  .control input {
    flex: 3;
    font: inherit;
    padding: 0.8rem;
    border-radius: 6px;
    border: 2px solid #1e8570;
  }
  
  .control input:focus {
    outline: none;
    border-color: #6eaca0;
    background: #eaf9f6;
  }
  
  .control.invalid input {
    border-color: red;
    background: #fbdada;
  }

  @media (min-width: 700px) {
 .control label {
  font-size: 30px;
 }
  
  }