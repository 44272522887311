/* .loginHeading{
    text-align: center;
    margin: 20px 0;
}

.loginHeading h4{
    margin: 15px 0;
    color: rgb(209, 177, 129);
}

.LoginInput input{
    width: 90%;
    margin: 15px;
    padding: 15px;
    border: 2px solid #106e5b;
    border-radius: 10px;
}

.button button {
    width: 90%;
    padding: 10px;
    margin: 15px;
    color: white;
    background-color: #106e5b;
    border: none;
    border-radius: 10px;
    font-size: 20px;
}

 */
 /* body{

    background-color:rgb(253, 251, 247);
} */

.loginHeading{
    text-align: center;
    /* margin: 20px 0; */
    color: #074d3f;
    font-size: large;
}

.loginHeading h5{
    font-size: 15px;
    margin: 15px 0 50px 0;
    /* color: rgb(209, 177, 129); */
    color: #106e5b;
}


.heading{
    color: #106e5b;
}

.line{
    border: 2px solid #106e5b;
    margin-top: -11px;
    margin-bottom: 3rem;
}
.button button {
    color: white;
    background-color: #106e5b;
    margin-bottom: 0.6rem;
    margin-top: 1rem;
}

.signup p {
    margin-left: 10px;
}
input[type=number]{
    color:#106e5b;
    font-weight: 500;
    font-size: 20px;
}
input[type=password]{
    color:#106e5b;
    font-weight: 500;
}
.checkboxContainer{
    display: flex;
    justify-content:space-between;
}

.forgetPassword {
    color: #106e5b;
    margin-top: 1.2rem;
    font-size: 16px;
}

@media (max-width: 700px) {
   .button button {
    width: 100%;  
   }

   .checkboxContainer{
    display: flex;
    flex-direction: column;
}
}
