
  .about_background{
    display: flex;
    width: 100%;
    min-height: 80vh;
  }
  .about_container{
    margin: auto;
  }
  .float_container {
    display: flex;
    /* gap: 50px; */
  
  }
  .about_img {
    width: 90%;
    /* margin-top: 1rem; */
  }
  
  .float_child { 
    width: 50%;
    /* padding: 10px 15px; */
  } 
  
  .left{
    padding-top: 2rem;
    color: white;
  }
  .right {
    padding:2rem ;
  }
  .right h1{
    font-size: 3rem;
    font-weight: 400;
    color: black;
  }

  .about_form_group{
    display: flex;
  }

  .contact{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 1rem;
  }

  .firstName {
    display: flex;
    flex-direction: column;
  }
  .firstName .lastName label {
    font-size: 14px;  
  }
  .lastName {
    display: flex;
    flex-direction: column;
    
  }
  
  .firstName input[type=text] {
    background-color:#DDBEA6;
    width: 88%;
    height: 30px;
    border: none;
    padding: 0.6rem;
    margin-top: 0.5rem;
    border-bottom:2px solid #015243;
  }
  .lastName input[type=email] {
    background-color: #DDBEA6;
    width: 84%;
    height: 30px;
    font-size: 18px;
    padding: 0.6rem;
    border: none;
    margin-top: 0.5rem;
    border-bottom:2px solid #015243;
  }
  .message{
    margin-top: 1rem;
  }
  .message textarea {
    font-size: 18px;
    width: 84%;
    height: 100px;
    margin-top: 0.5rem;
    padding: 0.8rem;
    background-color: #DDBEA6;
    margin-bottom: 2rem;
    border-bottom:2px solid #015243;
  }
  .contactButton button {
    background-color: #015243;
    color: rgb(207, 199, 199);
    border-radius: 20px;
  }

  @media screen and (min-width: 1400px) {
    .lastName {
      width: 84%;
    }
    .message {
      width: 84%;
    }
  }

  @media screen and (max-width: 900px) {
    .heading h1{
      margin:2rem 0 0.5rem 0;
      font-size: 32px;
      font-weight: 600;
    }
    .about_background{
        min-height: 40vh;
    }
    .about_container{
        margin: auto;
        padding: 10px;
      }
    .float_container{
        display: flex;
        flex-direction: column;
    }
    .float_child{
    width: 100%;
  }
    .about_img {
      width: 100%;
      margin-top: 0.1rem;
    }
    .contact{
      display: flex;
      flex-direction: column;
      gap: 01rem;
    }
  
  .left{
    padding: 0.1rem 1rem;
  }
  .right h1{
    font-size: 1.6rem;
  }
  .right {
    padding:1rem ;
  }
  .about_form_group{
    display: flex;
    flex-direction: column;
  }
  .firstName input[type=text] {
    width: 100%;
  }
  .lastName input[type=email] {
    width: 100%;
  }

  .message textarea {
    width: 100%;

  }
  
  }