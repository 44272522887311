
.polygon {
    height: 20px;
    width: 20px;
    background-color: #106e5b;
    rotate: 45deg;
}
.heading{
    color: #106e5b;
    margin-bottom: 3rem;
}

.line{
    border: 2px solid #106e5b;
    margin-top: -11px;
    margin-bottom: 3rem;
}

.button button {
    width: 50%;
    padding: 0.5rem;
    color: #106e5b;
    background-color: #ebf7f5;
    border: rgb(16, 11, 2);
    box-shadow: 10px rgb(238, 244, 242);
    border-radius: 10px;
    font-size: 30px;
}
input[type=text]{
    color:#106e5b;
    font-weight: 500;
    font-size: 20px;
}

/* .button button {
    width: 50%;
    padding: 12px;
    background-color: #ebf7f5;
    border: rgb(16, 11, 2);
    box-shadow: 10px rgb(238, 244, 242);
    border-radius: 10px;
    font-size: 20px;
} */

@media (max-width: 700px) {
   .button button {
    width: 100%;
   }
}