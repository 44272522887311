* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

html {
	font-family: 'Darker Grotesque', sans-serif;
}
h1,h2,h3{
	font-family: 'Darker Grotesque', sans-serif;
}
p{
	font-family: 'Darker Grotesque', sans-serif;
}
label{
	font-family: 'Darker Grotesque', sans-serif;
}

body{
    background-color: #FAECE1;
}